import React, {useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {useTranslation} from "react-i18next";

const Contact = () => {
    const {t} = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        description: "",
    });

    const data = useStaticQuery(graphql`
        query {
            stageImage4: file(relativePath: {eq: "index/stages/stage-4.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    const handleSubmit = e => {
        e.preventDefault();

        const loaderOverlay = document.querySelector("#loader-overlay");
        const successOverlay = document.querySelector("#success-overlay");
        const failOverlay = document.querySelector("#fail-overlay");
        loaderOverlay.style.zIndex = "10000";
        loaderOverlay.style.opacity = "1";

        let form = new FormData();
        for (let key in formData) {
            form.append(key, formData[key]);
        }
        for (let i = 0; i < fileList.length; i++) {
            form.append("upfiles", fileList[i]);
        }

        fetch("https://growire.org/api/emails/send", {
            // fetch("http://localhost:9000/api/emails/send", {
            method: "POST",
            body: form,
        })
            .then(response => {
                if (response.status !== 200) throw response;
                //close loader
                loaderOverlay.style.opacity = "0";
                setTimeout(() => (loaderOverlay.style.zIndex = "-1"), 1000);
                //open success
                successOverlay.style.zIndex = "10000";
                successOverlay.style.opacity = "1";
                window.ym(53259922, "reachGoal", "sendRequest", formData);
            })
            .catch(response => {
                console.log(response);
                //close loader
                loaderOverlay.style.opacity = "0";
                setTimeout(() => (loaderOverlay.style.zIndex = "-1"), 1000);
                //open fail
                failOverlay.style.zIndex = "10000";
                failOverlay.style.opacity = "1";
                window.ym(53259922, "failSend", "sendRequest", formData);
            });
        return true;
    };

    const handleChange = (data, type) => {
        setFormData({...formData, [type]: data});
    };

    const fileNames = e => {
        let {files} = e.target;
        let tempArr = [];

        for (let i = 0; i < files.length; i++) {
            tempArr.push(files[i]);
        }
        setFileList(fileList.concat(tempArr));
    };

    const deleteFile = index => {
        setFileList(
            fileList.filter((item, itemIndex) => {
                return index !== itemIndex;
            }),
        );
    };

    const validate = input => {
        let inputTag = document.querySelector(`#${input}`);
        let errorMsg = document.querySelector(`.error-${input}`);
        if (!inputTag.value.length) {
            errorMsg.style.display = "block";
            inputTag.classList.add("invalid");
        } else {
            errorMsg.style.display = "none";
            inputTag.classList.remove("invalid");
        }
    };

    const validation = () => {
        validate("name");
        validate("email");
        validate("phone");
    };

    return (
        <div className="contact" id="contact">
            <div className="contact-title">{t("contact.title")}</div>
            <div className="contact-wrapper">
                <form onSubmit={handleSubmit} name="form-name" className="contact-wrapper-info">
                    <div className="inputs">
                        <input
                            id="name"
                            type="text"
                            placeholder={t("contact.name")}
                            name="name"
                            onChange={e => handleChange(e.target.value, "name")}
                            required
                        />
                        <div className="error-name">Заполните поле, чтобы мы могли связаться с вами</div>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder={t("contact.email")}
                            onChange={e => handleChange(e.target.value, "email")}
                            required
                        />
                        <div className="error-email">Заполните поле, чтобы мы могли связаться с вами</div>
                        <input
                            id="phone"
                            type="text"
                            name="phone"
                            placeholder={t("contact.phone")}
                            onChange={e => handleChange(e.target.value, "phone")}
                            required
                        />
                        <div className="error-phone">Заполните поле, чтобы мы могли связаться с вами</div>
                        <textarea
                            id="company"
                            name="projectInfo"
                            placeholder={t("contact.description")}
                            onChange={e => handleChange(e.target.value, "description")}
                        >
                            {null}
                        </textarea>
                    </div>
                    <div className="uploadedFiles">
                        <ul>
                            {fileList.map((item, index) => (
                                <li key={item.lastModified}>
                                    <p onClick={() => deleteFile(index)}>&#x2715;</p>
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="buttons">
                        <div className="upload-files">
                            <input
                                onChange={e => {
                                    fileNames(e);
                                }}
                                id="image-file"
                                type="file"
                                name="upfiles"
                                multiple
                            />
                            {t("contact.button.add")}
                        </div>
                        <button className="send-file" onClick={validation}>
                            {t("contact.button.send")}
                        </button>
                    </div>
                </form>
                <div className="contact-wrapper-image">
                    <GatsbyImage alt="" image={data.stageImage4.childImageSharp.gatsbyImageData} />
                </div>
            </div>
        </div>
    );
};

export default Contact;
